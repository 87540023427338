/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const RecordSvg = ({ primaryColor }) => (
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.5003 3.68225L14.4551 8.0003L20.5003 12.3183V3.68225Z" stroke={primaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.7269 1.95471H3.22722C2.2733 1.95471 1.5 2.72801 1.5 3.68193V12.318C1.5 13.2719 2.2733 14.0452 3.22722 14.0452H12.7269C13.6808 14.0452 14.4541 13.2719 14.4541 12.318V3.68193C14.4541 2.72801 13.6808 1.95471 12.7269 1.95471Z"
      stroke={primaryColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RecordSvg;
