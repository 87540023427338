/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const ChangeOrgSvg = ({ primaryColor }) => (
  <>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.78366 1.00006H2.81782C2.09167 1.00006 1.50098 1.59082 1.50098 2.31705V7.28342C1.50098 8.00965 2.09167 8.60041 2.81782 8.60041H7.78366C8.50982 8.60041 9.10051 8.00965 9.10051 7.28342V2.31705C9.10039 1.59082 8.50982 1.00006 7.78366 1.00006Z"
        stroke={primaryColor}
        strokeWidth="2"
      />
      <path
        d="M18.1831 1.00006H13.2172C12.4911 1.00006 11.9004 1.59082 11.9004 2.31705V7.28342C11.9004 8.00965 12.4911 8.60041 13.2172 8.60041H18.1831C18.9092 8.60041 19.4999 8.00965 19.4999 7.28342V2.31705C19.4999 1.59082 18.9092 1.00006 18.1831 1.00006Z"
        stroke={primaryColor}
        strokeWidth="2"
      />
      <path
        d="M7.78269 11.3996H2.81685C2.09069 11.3996 1.5 11.9903 1.5 12.7165V17.6829C1.5 18.4091 2.09069 18.9998 2.81685 18.9998H7.78269C8.50884 18.9998 9.09953 18.4091 9.09953 17.6829V12.7165C9.09942 11.9903 8.50884 11.3996 7.78269 11.3996Z"
        stroke={primaryColor}
        strokeWidth="2"
      />
      <path
        d="M18.1821 11.3996H13.2163C12.4901 11.3996 11.8994 11.9903 11.8994 12.7166V17.6829C11.8994 18.4092 12.4901 18.9999 13.2163 18.9999H18.1821C18.9083 18.9999 19.499 18.4091 19.499 17.6829V12.7165C19.499 11.9903 18.9083 11.3996 18.1821 11.3996Z"
        stroke={primaryColor}
        strokeWidth="2"
      />
    </svg>
  </>
);

export default ChangeOrgSvg;
