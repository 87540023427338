/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const ChangeOrgSvg = ({ primaryColor }) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.58594 21.002H7.56555C7.88915 21.002 8.15149 20.7396 8.15149 20.416V19.7632C8.15149 18.4086 7.39434 17.2276 6.28105 16.621C6.67645 16.2012 6.91953 15.6364 6.91953 15.0156C6.91953 13.7233 5.86812 12.6719 4.57577 12.6719C3.28341 12.6719 2.232 13.7233 2.232 15.0156C2.232 15.6364 2.47509 16.2012 2.87048 16.621C1.75715 17.2276 1 18.4086 1 19.7632V20.416C1 20.7396 1.26235 21.002 1.58594 21.002ZM3.40388 15.0156C3.40388 14.3695 3.92959 13.8438 4.57577 13.8438C5.22194 13.8438 5.74765 14.3695 5.74765 15.0156C5.74765 15.6618 5.22194 16.1875 4.57577 16.1875C3.92959 16.1875 3.40388 15.6618 3.40388 15.0156ZM4.57577 17.3594C5.83113 17.3594 6.97961 18.3435 6.97961 19.8301H2.17188C2.17188 18.3512 3.31369 17.3594 4.57577 17.3594Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
    <path
      d="M19.1294 16.621C19.5248 16.2012 19.7679 15.6364 19.7679 15.0156C19.7679 13.7233 18.7165 12.6719 17.4241 12.6719C16.1318 12.6719 15.0803 13.7233 15.0803 15.0156C15.0803 15.6364 15.3234 16.2012 15.7188 16.621C14.6055 17.2276 13.8484 18.4086 13.8484 19.7632V20.416C13.8484 20.7396 14.1107 21.002 14.4343 21.002H20.4139C20.7375 21.002 20.9998 20.7396 20.9998 20.416V19.7632C20.9998 18.4086 20.2427 17.2276 19.1294 16.621ZM16.2522 15.0156C16.2522 14.3695 16.7779 13.8438 17.4241 13.8438C18.0703 13.8438 18.596 14.3695 18.596 15.0156C18.596 15.6618 18.0703 16.1875 17.4241 16.1875C16.7779 16.1875 16.2522 15.6618 16.2522 15.0156ZM19.828 19.8301H15.0202C15.0202 18.3512 16.162 17.3594 17.4241 17.3594C18.6794 17.3594 19.828 18.3434 19.828 19.8301Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
    <path
      d="M14.5758 8.74414V8.09133C14.5758 6.73676 13.8187 5.5557 12.7054 4.9491C13.1007 4.52934 13.3438 3.96457 13.3438 3.34375C13.3438 2.05141 12.2924 1 11 1C9.70769 1 8.65628 2.05141 8.65628 3.34375C8.65628 3.96457 8.89937 4.52934 9.29476 4.9491C8.18147 5.5557 7.42432 6.73676 7.42432 8.09133V8.74414C7.42432 9.06773 7.68666 9.33008 8.01026 9.33008H13.9899C14.3135 9.33008 14.5758 9.06773 14.5758 8.74414ZM9.82816 3.34375C9.82816 2.69758 10.3539 2.17188 11 2.17188C11.6462 2.17188 12.1719 2.69758 12.1719 3.34375C12.1719 3.98992 11.6462 4.51562 11 4.51562C10.3539 4.51562 9.82816 3.98992 9.82816 3.34375ZM13.4039 8.1582H8.59616C8.59616 6.67934 9.73797 5.6875 11 5.6875C12.2553 5.6875 13.4039 6.67148 13.4039 8.1582Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
    <path
      d="M13.3672 15.8068C12.6017 16.0583 11.7945 16.1857 10.9681 16.1857C10.1706 16.1857 9.38969 16.0666 8.64707 15.8318C8.33843 15.7343 8.00925 15.9052 7.91171 16.2137C7.81413 16.5222 7.98515 16.8515 8.29366 16.949C9.1507 17.2201 10.0505 17.3576 10.968 17.3576C11.9189 17.3576 12.8492 17.2104 13.7329 16.9201C14.0404 16.8191 14.2077 16.488 14.1068 16.1806C14.0058 15.8732 13.6747 15.7057 13.3672 15.8068Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
    <path
      d="M5.59884 5.85547C4.95173 6.47925 4.40161 7.20441 3.96375 8.01085C3.51 8.84659 3.19547 9.73432 3.0289 10.6494C2.96363 11.0081 3.23883 11.3404 3.60606 11.3404C3.88371 11.3404 4.13036 11.1421 4.18184 10.8593C4.32614 10.0666 4.59927 9.29636 4.99361 8.57003C5.37419 7.86913 5.85142 7.23968 6.41213 6.69921C6.6451 6.47464 6.65194 6.10371 6.42737 5.8707C6.20275 5.63773 5.83185 5.6309 5.59884 5.85547V5.85547Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
    <path
      d="M16.4013 5.85548C16.1683 5.63087 15.7973 5.63775 15.5728 5.87072C15.3482 6.10372 15.355 6.47466 15.588 6.69923C16.1487 7.2397 16.626 7.86911 17.0065 8.57005C17.4009 9.29642 17.674 10.0667 17.8183 10.8593C17.8762 11.1773 18.1809 11.3888 18.4997 11.3308C18.818 11.2729 19.0292 10.9678 18.9712 10.6495C18.8047 9.73435 18.4901 8.84661 18.0364 8.01087C17.5985 7.20443 17.0484 6.47923 16.4013 5.85548Z"
      fill={primaryColor}
      stroke={primaryColor}
      strokeWidth="0.5"
    />
  </svg>
);

export default ChangeOrgSvg;
