/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const AdminSvg = ({ primaryColor }) => (
  <>
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6087 14.4782H16.5246C16.1858 13.3505 15.1496 12.5217 13.9131 12.5217C12.6766 12.5217 11.6411 13.3505 11.3015 14.4782H1.3913C0.9593 14.4782 0.608704 14.8288 0.608704 15.2608C0.608704 15.6928 0.9593 16.0434 1.3913 16.0434H11.3015C11.6403 17.1711 12.6765 17.9999 13.913 17.9999C15.1495 17.9999 16.185 17.1711 16.5246 16.0434H18.6087C19.0415 16.0434 19.3913 15.6928 19.3913 15.2608C19.3913 14.8288 19.0415 14.4782 18.6087 14.4782ZM13.9131 16.4347C13.2658 16.4347 12.7392 15.908 12.7392 15.2608C12.7392 14.6136 13.2658 14.0869 13.9131 14.0869C14.5603 14.0869 15.087 14.6136 15.087 15.2608C15.087 15.908 14.5603 16.4347 13.9131 16.4347Z"
        fill={primaryColor}
      />
      <path
        d="M18.6087 1.95651H16.5246C16.185 0.828783 15.1496 0 13.9131 0C12.6766 0 11.6411 0.828783 11.3015 1.95651H1.3913C0.9593 1.95651 0.608704 2.30711 0.608704 2.73911C0.608704 3.17111 0.9593 3.5217 1.3913 3.5217H11.3015C11.6411 4.64947 12.6765 5.47825 13.9131 5.47825C15.1496 5.47825 16.185 4.64947 16.5246 3.52174H18.6087C19.0415 3.52174 19.3913 3.17114 19.3913 2.73914C19.3913 2.30714 19.0415 1.95651 18.6087 1.95651ZM13.9131 3.91302C13.2658 3.91302 12.7392 3.38634 12.7392 2.73911C12.7392 2.09188 13.2658 1.56519 13.9131 1.56519C14.5603 1.56519 15.087 2.09188 15.087 2.73911C15.087 3.38634 14.5603 3.91302 13.9131 3.91302Z"
        fill={primaryColor}
      />
      <path
        d="M18.6087 8.21746H8.69855C8.35888 7.08973 7.32349 6.26095 6.08699 6.26095C4.85049 6.26095 3.81507 7.08973 3.47544 8.21746H1.3913C0.9593 8.21746 0.608704 8.56806 0.608704 9.00006C0.608704 9.43206 0.9593 9.78266 1.3913 9.78266H3.4754C3.81507 10.9104 4.85046 11.7392 6.08696 11.7392C7.32345 11.7392 8.35888 10.9104 8.69851 9.78266H18.6087C19.0415 9.78266 19.3913 9.43206 19.3913 9.00006C19.3913 8.56806 19.0415 8.21746 18.6087 8.21746ZM6.08696 10.174C5.43972 10.174 4.91304 9.64729 4.91304 9.00006C4.91304 8.35283 5.43972 7.82614 6.08696 7.82614C6.73419 7.82614 7.26087 8.35283 7.26087 9.00006C7.26087 9.64729 6.73419 10.174 6.08696 10.174Z"
        fill={primaryColor}
      />
    </svg>
  </>
);

export default AdminSvg;
