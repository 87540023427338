/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';

const HelpSvg = ({ primaryColor }) => (
  <>
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 19C15.4706 19 19.5 14.9706 19.5 10C19.5 5.02944 15.4706 1 10.5 1C5.52944 1 1.5 5.02944 1.5 10C1.5 14.9706 5.52944 19 10.5 19Z"
        stroke={primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.88086 7.29991C8.09245 6.69841 8.5101 6.1912 9.05982 5.86813C9.60954 5.54505 10.2559 5.42695 10.8843 5.53475C11.5128 5.64255 12.0828 5.96928 12.4934 6.45708C12.9041 6.94489 13.1288 7.56228 13.1279 8.19991C13.1279 9.99991 10.4279 10.8999 10.4279 10.8999"
        stroke={primaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.5 14.5H10.5094" stroke={primaryColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </>
);

export default HelpSvg;
